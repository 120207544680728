var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("회원가입")]), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "form"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs text-right pb-16px pb-md-20px"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("* ")]), _vm._v("필수입력 항목입니다.")]), _c('vertical-form-table', _vm._b({
    on: {
      "certification": function (value) {
        return _vm.form._certification = value;
      }
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "비밀번호 확인"
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.form.password,
      "placeholder": "동일한 비밀번호를 재입력하세요."
    }
  })], 1), _c('template', {
    slot: "주소"
  }, [_c('v-address-field', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "btnAttrs": {
        large: true,
        color: 'primary'
      }
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 2), _c('div', {
    staticClass: "pt-30px pt-md-40px"
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "params": _vm.termsParams
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.join
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("가입")])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "result"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('icon-check')], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("회원가입이 완료되었습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px"
  }, [_vm._v("대시보드에서 추가 정보를 입력해 주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "color": "primary",
      "to": "/mypage"
    }
  }, [_vm._v("MY대시보드로 이동")])], 1)], 1)], 1)])])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }