<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">회원가입</template>

            <v-tabs-items v-model="tab">
                <!-- 회원가입정보입력 -->
                <v-tab-item value="form">
                    <v-form v-model="isValid">
                        <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>
                        <vertical-form-table v-model="form" v-bind="{ items }" @certification="(value) => (form._certification = value)">
                            <template slot="비밀번호 확인">
                                <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." />
                            </template>
                            <template slot="주소">
                                <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
                            </template>
                        </vertical-form-table>

                        <div class="pt-30px pt-md-40px">
                            <terms-of-agreements-short v-model="form._terms" :params="termsParams" @isValid="(isValid) => (isTermsValid = isValid)" />
                        </div>

                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn outlined color="grey" class="v-size--xx-large w-100 min-w-sm-200px" @click="$router.go(-1)">취소</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn color="primary" class="v-size--xx-large w-100 min-w-sm-200px" v-bind="{ disabled }" @click="join">가입</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-tab-item>
                <!-- 회원가입완료 -->
                <v-tab-item value="result">
                    <div class="text-center">
                        <div class="mb-18px mb-md-24px">
                            <icon-check />
                        </div>
                        <div class="tit tit--sm">회원가입이 완료되었습니다.</div>
                        <div class="txt txt--sm mt-8px">대시보드에서 추가 정보를 입력해 주세요.</div>

                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn color="primary" to="/mypage" class="v-size--xx-large w-100 min-w-sm-200px">MY대시보드로 이동</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { initUser, rules } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import IconCheck from "@/components/client/icons/icon-check.vue";

const items = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        required: true,
        placeholder: "아이디를 입력하세요",
        rules: rules.username,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        required: true,
        placeholder: "비밀번호를 입력하세요",
        rules: rules.password,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
        placeholder: "동일한 비밀번호를 재입력하세요.",
    },
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
        placeholder: "이름을 입력하세요.",
        rules: rules.name,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
        placeholder: "이메일을 입력하세요.",
        rules: rules.email,
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
        hasCertification: true,
    },
    {
        term: "주소",
        type: "address",
        required: true,
    },
    {
        key: "promoterCode",
        term: "추천인코드",
        type: "text",
        required: true,
        placeholder: "추천인코드를 입력하세요.",
        rules: rules.promoterCode,
    },
].map((item) => ({ ...item, outlined: true }));

const termsParams = { code: { $nin: ["member-management", "personal-information-collection"] } };

export default {
    components: {
        ClientPage,
        PageSection,
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        TermsOfAgreementsShort,
        IconCheck,
    },
    data: () => ({
        form: initUser(),

        tab: "form",
        // tab: "result",

        items,
        termsParams,

        isValid: false,
        isTermsValid: undefined,
    }),
    computed: {
        promoterCode() {
            return this.$route.query.promoterCode || null;
        },
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    mounted() {
        let { promoterCode } = this;
        this.form = initUser({ promoterCode });
    },
    methods: {
        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async join() {
            if (!this.validates()) return;
            if (this.loading) return;
            this.loading = true;

            try {
                const form = {
                    ...this.form,
                    password: CryptoAES.encrypt(this.form.password),
                };
                await api.v1.users.post(form);

                await this.$store.dispatch("login", { username: this.form.username, password: this.form.password });

                this.tab = "result";
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
